.order-details-container {
    font-family: Arial, sans-serif;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background: #2c2f33;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .order-details-table {
    width: 100%;
    border-collapse: collapse;
    background: #23272a;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .order-details-table th,
  .order-details-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #40444b;
    color: white;
  }
  
  .order-details-table th {
    background: #7289da;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .order-details-table input {
    width: 100%;
    padding: 8px;
    border: 1px solid #444;
    border-radius: 5px;
    background: #23272a;
    color: white;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .order-details-table input:focus {
    border-color: #7289da;
  }
  
  button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background: #7289da;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  button:hover {
    background: #5a6faf;
  }
  
  button:active {
    transform: scale(0.98);
  }
  
  .order-details-table tr:last-child td {
    border-bottom: none;
  }
  
  @media (max-width: 768px) {
    .order-details-container {
      padding: 15px;
    }
  
    .order-details-table th,
    .order-details-table td {
      padding: 10px;
    }
  
    button {
      width: 100%;
      text-align: center;
    }
  }
  