.side-nav {
  position: fixed;
  top: 30;
  width: 15%;
  height: 100%;
  background-color: black;
  padding-top: 20px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: flex-start;
}

.option-container {
  /* position: relative; */
  width: 90%;
  margin-left: 10%;
}

.option-chat-btn {
  background-color: transparent;
  color: white;
  padding: 15px 20px;
  border: 2px solid #3c94fc;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  width: calc(100% - 80px);
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.option-chat-btn:hover {
  background-color: #3c94fc;
  box-shadow: 0px 0px 15px 5px rgba(60, 148, 252, 0.6);
  transform: scale(1.05);
  outline: none;
}

.option-chat-btn.active {
  background-color: #3c94fc;
  box-shadow: 0px 0px 15px 5px rgba(60, 148, 252, 0.6);
}

.button-icon {
  margin-right: 8px;
}

.new-chat-btn,
.dropdown-btn {
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.5rem;
  align-items: center;
}

.new-chat-btn:hover,
.dropdown-btn:hover {
  background-color: black;
  outline: none;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.dropdown-item {
  background-color: transparent;
  color: #3c94fc;
  padding: 10px 20px;
  border: 1px solid #3c94fc;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-top: 5px;
}

.dropdown-item:hover {
  background-color: #3c94fc;
  box-shadow: 0px 0px 10px 3px rgba(60, 148, 252, 0.6);
  transform: scale(1.02);
  outline: none;
}

.dropdown-item.active {
  background-color: #3c94fc;
  color: white;
  box-shadow: 0px 0px 10px 3px rgba(60, 148, 252, 0.6);
}
