* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: 'Arial', sans-serif;
  background-color: black;
  height: 100%; 
}


.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 92.3vh;
  width: 100%;
  background-color: black;
}

.welcome-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-heading {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.welcome-option-btn {
  background-color: transparent;
  color: white;
  padding: 20px 40px;
  border: 2px solid #3c94fc;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.5rem;
  width: 300px;
  text-align: center;
  transition: all 0.3s ease;
}

.welcome-option-btn:hover {
  background-color: #3c94fc;
  box-shadow: 0px 0px 15px 5px rgba(60, 148, 252, 0.6);
  transform: scale(1.05);
}

/* Chat layout */
.chat {
  display: flex;
  flex-basis: 85%;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

/* Chat options/sidebar */
.chat-options {
  flex-basis: 15%;
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: black;
}

.chat-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-container {
  display: flex;
  width: 100%;
  flex-direction: row; 
  align-items: center; 
  gap: 1px; 
  margin-bottom: 15px; 
  position: relative;
  overflow: visible;
}

.option-chat-btn {
  background-color: transparent;
  color: white;
  padding: 15px 30px;
  border: 2px solid #3c94fc;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px; 
  width: 100%;
  text-align: center;
  transition: all 0.3s ease;
}

.option-chat-btn:hover {
  background-color: #3c94fc; 
  box-shadow: 0px 0px 15px 5px rgba(60, 148, 252, 0.6);  /* Add shadow effect */
  transform: scale(1.05);  /* Slightly increase the size when hovered */
  outline: none;
}

.option-chat-btn.active {
  background-color: #3c94fc;
  box-shadow: 0px 0px 15px 5px rgba(60, 148, 252, 0.6);
}

.new-chat-btn {
  background-color: transparent;
  position: absolute;
  right: -40px; 
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
}

.new-chat-btn:hover {
  background-color: black;  
  box-shadow: 0px 0px 15px 5px rgba(105, 114, 126, 0.6); /* Add shadow effect */
  outline: none;
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: black;
  height: calc(100vh - 1px);
  padding: 0;
}

.chat-body {
  flex: 1;
  padding-left: 30%;
  padding-right: 15%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.chat-body::-webkit-scrollbar {
  width: 0px; 
  background: transparent;
}

.chat-message {
  background-color: #3c94fc;
  padding: 15px;
  border-radius: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: white;
}

.user-message {
  font-size: 1.1rem;
  background-color: transparent;
}

.system-message {
  background-color: transparent;
  color: white;
  font-size: 1.1rem;
  color: #3c94fc;
}

.request-completed-option {
  color: white;
  font-style: italic;
  background-color: transparent;
  border-bottom: 2px solid #3c94fc;
  align-self: flex-start;
}

.complete-button{
  background-color: transparent;
  color: #3c94fc;
  font-size: 20px;
  border: 1px solid #3c94fc;
  border-radius: 20px;
}

.complete-button:hover{
  background-color: #3c94fc;
  color: white;
  border-radius: 20px;
}

.chat-footer {
  display: flex;
  align-items: center;
  padding-left: 25%;
  padding-right: 15%;
  padding-bottom: 5%;
  padding-top: 2%;
  background-color: black;
  position: relative;
  width: 100%;
}

.chat-footer textarea.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #3c94fc;
  border-radius: 20px;
  background-color: black;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  margin-right: 10px;
  overflow-y: auto; 
  max-height: 25rem; 
}


.chat-footer input::placeholder {
  color: #aaa;
}

.file-upload-btn {
  background-color: transparent;
  color: white;
  font-size: 24px;
  text-align: center;
  transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.file-upload-btn:hover {
  box-shadow: 0px 0px 8px 3px #3c94fc;
  background-color: #3c94fc;
  transform: scale(1.05);
}

.send-btn {
  background-color: transparent;
  color: white;
  font-size: 24px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.send-btn:hover {
  box-shadow: 0px 0px 8px 3px #3c94fc;
  background-color: #3c94fc;
  transform: scale(1.05);
}

.send-btn:hover {
  box-shadow: 0px 0px 8px 3px #3c94fc;  
  background-color: #3c94fc; 
  transform: scale(1.05); 
}

.prompt-message {
  background-color: white; 
  border-left: 4px solid white; 
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  max-width: 70%; 
}

.prompt-message span {
  color: #4b4b4b;
}

.prompt-message .done-btn {
  background-color: #3c83f5;
  border: none;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px; 
}

.prompt-message .done-btn:hover {
  background-color: #28283c;
}

.generate-pdf-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 24px;
  margin: 10px 0;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
