.message-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #212429; /* Black background */
  border: 1px solid #3c94fc; /* Blue border */
  color: #ffffff; /* White text */
  padding: 20px 30px;
  border-radius: 6px;
  outline: none;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-modal-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.message-modal-content {
  margin-bottom: 20px;
  text-align: center;
}

.message-modal-actions {
  display: flex;
  justify-content: center;
}

.message-modal-button {
  color: #ffffff; /* Button text color */
  background-color: #27292b; /* Button color */
  margin-left: 10px;
  text-transform: none;
}

.message-modal-button:hover {
  background-color: #3c94fc;
}

/* New Animation Styles for "Waiting" */
.pulsing-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3c94fc; /* Blue color matching the border */
  animation: pulse 2s infinite;
  margin-right: 15px;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .message-modal-box {
    padding: 15px 20px;
    min-width: 250px;
  }

  .message-modal-header {
    font-size: 1rem;
  }

  .message-modal-button {
    padding: 8px 16px;
    font-size: 200px;
  }
}
