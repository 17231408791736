
   .container {
    position: relative;
    height: 100vh; 
  }
  
  .spinner {
    border: 6px solid #3c94fc;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #3c94fc;
    animation: spin 1s ease infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the spinner */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg) translate(-50%, -50%); /* Maintain centering during spin */
    }
    100% {
      transform: rotate(360deg) translate(-50%, -50%);
    }
  }
  