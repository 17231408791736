.custom-body{
  flex: 1;
  padding-left: 30%;
  padding-right: 15%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.custom-window{
  flex: 1;
  display: flex;
  padding-left: 30%;
  flex-direction: column;
  background-color: black;
  padding: 0;
}

.file-input{
  border: 1px solid #3c94fc;
  color: white;
  font-size: 20px;
  font-weight: lighter;
  padding: 20px;
}

label{
  color: white;
  font-size: 17px;
  font-weight: lighter;
}

.border-input {
  background-color: black;
  color: white;
  border: 1px solid #3c94fc;
  font-weight: lighter;
}
.border-input:focus {
  border-color: #3c94fc; 
  box-shadow: 0 0 5px #3c94fc; 
}

.border-input:not(:focus) {
  border-color: #3c94fc; 
}

input::placeholder {
  color: white;
}

button{
  background-color: black;
  color: #3c94fc;
  font-size: 18px;
  border-radius: 15px;
  border: 1px solid #3c94fc;
}

button:hover{
  background-color: #3c94fc;
  box-shadow: 0px 0px 15px 5px #3c94fc;
  outline: none;
}

.message {
  margin-top: 20px;
  text-align: center;
  color: white;
}

.border-input-container {
  position: relative;
  width: 100%;
}

.dropdown {
  position: absolute;
  top: calc(100% + 5px); 
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000; 
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: #3c94fc;
}

.dropdown-item:hover {
  background-color: #3c94fc;
}

.input-label {
  font-weight: 300; 
  color: white; 
  font-size: 14px; 
}

.text-field-standard .MuiOutlinedInput-root {
  border: 1px solid #3c94fc; 
}

.text-field-standard .MuiOutlinedInput-root:hover {
  border: 1px solid #3c94fc; 
}

.text-field-standard .MuiOutlinedInput-root.Mui-focused {
  border: 1px solid #3c94fc; 
}

.text-field-standard .MuiOutlinedInput-notchedOutline {
  border: none !important; 
}

.border-input {
  width: 100%;                /* Ensure it stretches the full width */
  padding: 10px;              /* Same padding as your file-input or text inputs */
  background-color: black;
  color: white;
  border: 1px solid #3c94fc;
  font-weight: lighter;
  font-size: 14px;            /* Match your input font size */
  border-radius: 4px;         /* (Optional) add some rounding if you like */
  box-sizing: border-box;     /* Make sure padding doesn't overflow the container */
}

.label-inline {
  margin: 4px; /* reduce spacing between label and input */
}




