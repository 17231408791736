body {
    margin: 0;
    background-color: black;
    color: #ffffff; /* Optional: Ensures text color is readable */
}
  
#root {
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.order-history-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: black;
    padding: 0;
}
  
.order-history-body {
    flex: 1;
    padding-left: 17%;
    padding-right: 2%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.order-history-container {
    display: flex;
    background-color: black;
}
