.App {
  text-align: center;
}

.pdfEditorWindow{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: black;
  padding: 0;
}

.pdfEditorBody {
  flex: 1;
  padding-left: 23%;
  padding-right: 10%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.App-header {
  background-color: black;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px 0;
}

canvas {
  width: 1000px;
  height: auto; 
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; 
  margin-top: 10px; 
}

input[type="color"],
input[type="number"],
textarea {
  padding: 8px; 
  border-radius: 4px; 
  color: white;
  background-color: black;
  border: 1px solid #3c94fc; 
  margin: 2px; 
}

.pdfInput{
  border: 1px solid #3c94fc;
  padding: 10px;
}

@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    justify-content: flex-start; 
  }

  /* canvas {
    max-width: 90vw; 
  } */
}
