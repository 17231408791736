body {
  margin: 0;
  background-color: black;
  color: #ffffff; /* Optional: Ensures text color is readable */
}

#root {
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.broker-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: black;
    padding: 0;
  }
  
  .broker-body {
    flex: 1;
    padding-left: 17%;
    padding-right: 2%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

.add-button{
  background-color: transparent;
  color: white;
  margin-left: 25px;
  padding: 10px 20px;
  font-size: 15px;
  border: 1px solid #3c94fc;
  border-radius: 15px;
}

.add-button:hover {
  background-color: #3c94fc;
  box-shadow: 0px 0px 15px 5px rgba(60, 148, 252, 0.6);
  transform: scale(1.05);
  outline: none;
}
.upload-pdf{
  background-color: black;
  color: white;
  font-size: 15px;
  border: 1px solid #3c94fc;
  border-radius: 15px;
}

.paps-input{
  background-color: black;
  color: white;
  font-size: 15px;
}

.chat-container {
  display: flex;
  background-color: black;
}

.MuiTableCell-root {
  color: #ffffff;
}

.MuiTypography-root {
  color: #ffffff;
}