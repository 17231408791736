/* GoogleMapsLoadingIndicator.css */
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.lottie-animation {
  width: 400px;
  height: 200px;
}

.loading-text {
  margin-top: 20px;
  font-size: 22px;
  color: #2c3e50;
  font-weight: bold;
  text-align: center;
}
