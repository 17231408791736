/* TruckLoadingIndicator.css */

.loading-container {
    display: flex;
    justify-content: flex-start; /* Align to the left */
    align-items: center;
    height: 50px; /* Adjust the height as needed */
    width: 100%; /* Ensure it spans the width of the chat body */
    margin-top: 10px; /* Space above the loading indicator */
  }
  
  .dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: #3c94fc; /* Match your theme color */
    border-radius: 50%;
    opacity: 0.6;
    animation: fade 1.6s infinite ease-in-out; /* Increased duration for smoother animation */
  }
  
  .dot1 {
    animation-delay: -0.4s;
  }
  
  .dot2 {
    animation-delay: -0.3s;
  }
  
  .dot3 {
    animation-delay: -0.2s;
  }
  
  .dot4 {
    animation-delay: -0.1s;
  }
  
  @keyframes fade {
    0%, 80%, 100% {
      opacity: 0.6;
      transform: scale(0.8);
    }
    40% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Screen reader-only text */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .dot {
      width: 8px;
      height: 8px;
      margin-right: 4px;
    }
  }
  