.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    background-color: black;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 24px;
    border: 1px solid #3c94fc;
    border-radius: 8px;
  }
  
  .modal-header {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .modal-content {
    margin-top: 16px;
    font-size: 1rem;
    text-align: center;
  }
  
  .modal-actions {
    margin-top: 30px;
    display: flex;
    justify-content:space-between;
  }
  
  .modal-box .modal-button {
    margin-right: 8px;
    color: grey;
  }
  