Edit.css

.container {
  margin-top: 10px;
  display: flex;
  background-color: black;
}

.edit-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: black;
  padding: 0;
}

.edit-body {
  flex: 1;
  padding-left: 30%;
  padding-right: 15%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form-section {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  color: white; /* Ensure labels are visible on black background */
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 13px;
  border: 1px solid #3c94fc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: black; /* Dark background for inputs */
  color: white; /* White text for inputs */
}

input[type="file"]::file-selector-button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 16px;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  border-radius: 4px;
}

.dropdown-item {
  padding: 8px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.date-input{
  color: white;
  border: 1px solid #3c94fc;
}
