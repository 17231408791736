
   html, body {
    height: 100%; 
    margin: 0; 
    padding: 0; 
    font-family: 'Arial', sans-serif;
    background: #fff;
    color: black;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  
  input {
    display: block;
     margin: 10px auto; 
     padding: 10px; 
    border-radius: 5px;
    border: 2px solid black;
    font-size: 16px; 
    font-weight: bold;
  }
  
  button:hover {
    background-color: #cc7a00;
  }

  
  th, td {
    border: 1px solid #2893f3;
    padding: 10px;
    text-align: center;
    color: black; 
  }
  
  @media screen and (max-width: 600px) {
    table {
      width: 100%;
      display: block;
      overflow-x: auto; 
    }
  }

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.pagination button:last-child {
    margin-right: 0;
}

.pagination button:hover {
    background-color: #0056b3;
}

.profile-button { 
  background: none;
  border: none;
  color: black; 
  font-size: 30px; 
  margin-right: auto; 
  cursor: pointer;
  font-weight: bold;
}

.profile-button:hover {
  background-color: #ff9900; 
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-items: center;
  position: relative;
  background: #f4f4f4;  /* Ensures that the background matches the screenshot */
}

nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;  /* Aligns items vertically */
}

nav li {
  margin: 0 10px;  /* Adjust spacing as needed */
}

nav a, nav button {
  background: none;
  border: none;
  color: black;
  display: flex;
  align-items: center;
  padding: 8px 12px;  /* Adjust padding to ensure elements have the same height */
  text-decoration: none;
  font-size: 16px;  /* Ensure text size is consistent */
  cursor: pointer;
}

.profile-button {
  font-size: 24px; /* Icon size */
  padding: 8px 12px; /* Adjust padding to match the height of text links */
}

.profile-button svg {
  height: 1em;  /* Aligns the size of the icon with the text */
}

/* Add hover effects for links and buttons for better UI interaction */
nav a:hover, nav button:hover {
  background-color: #e8e8e8;  /* Light grey background on hover */
}

 /* Modal Styles */
 .paps-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px; /* Adjust width as needed */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  padding: 30px;
  outline: none; /* Remove default focus outline */
}

/* Modal Overlay */
.paps-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure modal is above other content */
}

.paps-modal .form-group { /* Style for better button alignment */
  display: flex;
  flex-direction: column; 
  gap: 15px; 
}

.paps-modal button {
  padding: 10px 20px; /* Adjust padding if needed */
}

.close-button {
  background-color: transparent; /* Make it look like a link */
  border: none;
  color: red;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  padding: 0; /* Remove default button padding */
}



@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
/* Additional styles for the modal form (optional) */
.paps-modal .form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.paps-modal label {
  font-weight: bold;
  margin-bottom: 5px;
}

.paps-modal input[type="text"],
.paps-modal input[type="email"],
.paps-modal input[type="tel"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Mobile Menu Styles */
@media (max-width: 767px) {
  nav ul {
    display: none;
    flex-direction: column; 
    position: absolute;
    top: 100%; 
    left: 0;  /* Align to the right */
    width: 250px; /* Set a fixed width for the menu */
    background-color: #fff; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
    padding: 20px; /* Add some padding inside the menu */
    border-radius: 8px; /* Rounded corners for the menu */
  }

  nav.open ul { /* Target the 'open' class to display the menu */
    display: flex; /* Change to flex to make it visible */
    z-index: 1002;
  }

  nav li {
    margin: 10px 0; /* Space out the menu items */
    width: 100%; 
    text-align: left; /* Align text to the left */
  }

  nav a, nav button {
    padding: 10px; 
    display: block; 
    color: #333; /* Darker text for better contrast */
    text-decoration: none;
    transition: background-color 0.3s ease; /* Add a transition for hover effect */
  }

  nav a:hover, nav button:hover {
    background-color: #f8f9fa; /* Subtle background on hover */
  }
}

/* Hamburger Menu */
.hamburger-menu {
  display: none; 
  cursor: pointer;
  font-size: 24px; 
  z-index: 1001; /* Make sure it's above the menu */
  background-color: transparent;
  padding: 8px; /* Add padding around the icon */
}

@media (max-width: 767px) {
  .hamburger-menu {
    display: block;
    position: absolute; /* Position at the top right */
    top: 10px;
    left: 10px;
  }
}



