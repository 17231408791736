.container {
    display: flex;
    height: 100vh;
    background-color: black;
    color: #ffffff;
  }
  
  .edit-truck-window {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .edit-truck-body {
    flex: 1;
    padding-left: 17%;
    padding-right: 2%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .edit-truck-paper {
    width: 100%;
    background-color: black;
    border-radius: 8px;
  }
  
  .edit-truck-form {
    display: flex;
    flex-direction: column;
  }
  
  .edit-truck-form .MuiFormControl-root {
    margin-bottom: 16px;
  }
  
  .MuiButton-containedPrimary {
    background-color: #3c94fc;
    color: #ffffff;
  }
  
  .MuiButton-outlinedSecondary {
    border-color: #f50057;
    color: #f50057;
  }

  form {
    display: flex;
    /* width: 50%; */
  }
  